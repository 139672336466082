.contacts-urgence-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact-table {
  margin-bottom: 0;
}

.contact-table thead th {
  background-color: #003da5;
  color: #fff;
  font-weight: 500;
  padding: 16px;
  border: none;
}

.contact-item td {
  padding: 16px;
  vertical-align: middle;
}

.contact-email,
.contact-phone {
  color: #003da5;
  text-decoration: none;
  transition: color 0.2s;
}

.contact-email:hover,
.contact-phone:hover {
  color: #0056b3;
  text-decoration: underline;
}
