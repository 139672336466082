.page-body-container {
  padding: 2rem 0;
}

.page-content-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.page-first-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.adding-item-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f5f8ff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.adding-item-container:hover {
  background-color: #e9efff;
}

.icon-container {
  margin-right: 0.5rem;
}

.adding-item-label {
  color: #003da5;
  font-weight: 500;
}

.search-container {
  max-width: 350px;
}

.parent-table-container {
  margin-top: 1rem;
}

.table th {
  background-color: #f5f8ff;
  color: #003da5;
  font-weight: 500;
  border-top: none;
}

.table td {
  vertical-align: middle;
}

.pagination-container {
  margin-top: 1.5rem;
}

.pagination .page-item.active .page-link {
  background-color: #003da5;
  border-color: #003da5;
}

.pagination .page-link {
  color: #003da5;
}

.action-btn {
  background-color: transparent;
  border: none;
  color: #6c757d;
  padding: 0.25rem 0.5rem;
}

.action-btn:hover,
.action-btn:focus {
  background-color: rgba(0, 61, 165, 0.1);
  color: #003da5;
}

.action-dropdown {
  min-width: 120px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  background-color: rgba(0, 61, 165, 0.1);
  color: #003da5;
}
